<template>
  <div class="mb-2">
    <v-data-table
      v-for="(data,index) in dataset"
      :key="index"
      :headers="headers"
      :items="data"
      :items-per-page="5"
      class="elevation-1 mb-5"
    >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ index }}
        </v-toolbar-title>
      </v-toolbar>
    </template>
  </v-data-table>
  </div>
</template>

<script >
  import sytheses from '@/services/syntheses.js'
  export default {
    data () {
      return {
        dataset: Object,
        tableData: [],
        headers: [

          {
            text: 'Année',
            value: 'annee',
          },
          {
            text: 'Valeur',
            value: 'valeur',
          },
          {
            text: 'Mésure',
            value: 'mesure',
          },
        ],
      }
    },
    mounted () {
      sytheses.getSyntheseTableauData().then(response => {
        this.dataset = response.data
        this.tableData = Object.keys(response.data).map((element) => {
          return this.dataset[element]
        })
      }).catch(err => {
        console.log(err.data)
      })
    },
  }
</script>

<style lang="scss" scoped>

</style>
